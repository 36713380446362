import axios from 'utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from '../../shared/helperMethods/Selectors';
import { getAllFee, getAllfeeSuccess, getAllenable, getAllEnableSuccess } from './actions';
import { GET_ALL_Fee, GET_ALL_Enable } from './constants';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* getAllFeeRequest({ payload }) {
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };

    const response = yield axios.get(`/admin/get-platform-fee`, headers);
    yield put(getAllfeeSuccess(response.data.data));
    console.log(response, 'response****');
    // yield SetNotification('success', response.data.message);
  } catch (error) {
    console.log(error, 'error************');
  }
}

export function* watchGetAllEvents() {
  yield takeLatest(GET_ALL_Fee, getAllFeeRequest);
}

function* getAllEnableRequest({ payload }) {
  try {
    let data = {
      fee: payload.fee,
      enabled: payload.enabled,
      percentage: payload.percentage,
      isFeeEnabled: payload.isFeeEnabled,
      isPercentageEnabled: payload.isPercentageEnabled
    };
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };

    const response = yield axios.post(`/admin/enablefee`, data, headers);
    yield SetNotification('success', response.data.message);
    yield put(getAllfeeSuccess(response.data.data));
    console.log(response, 'response****');
  } catch (error) {
    console.log(error, 'error************');
  }
}

export function* watchGetAllEnable() {
  yield takeLatest(GET_ALL_Enable, getAllEnableRequest);
}
export default function* eventsSaga() {
  yield all([fork(watchGetAllEvents)]);
  yield all([fork(watchGetAllEnable)]);
}
