import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  OutlinedInput,
  Paper,
  Popper,
  Stack,
  Switch,
  Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FormattedMessage } from 'react-intl';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
// import useAuth from 'hooks/useAuth';
import User1 from 'assets/images/users/user-round.svg';
import { logout } from '../../../../redux/auth/actions';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  // language customization

  const customization = useSelector((state) => state.customization);
  const [language, setLanguage] = useState(customization.locale);
  useEffect(() => {
    setLanguage(customization.locale);
  }, [customization]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState('');
  const [notification, SetNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    try {
      await dispatch(logout());
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };
  const updateData = useSelector((state) => state.auth.user);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: '50px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
          backgroundColor: theme.palette.mode === 'dark' ?  '#499662' : ' #499662',
          '&[aria-controls="menu-list-grow"], &:hover': {
            
            background: ` #218838 !important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        icon={
          <Avatar
            src={updateData.profileImage}
            sx={{
              backgroundColor:'white',
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer'
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={<IconSettings stroke={1.8} size="1.8rem" color={'white'} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper sx={{background:'transparent',paddingTop:'0px'}}>
              <ClickAwayListener onClickAway={handleClose}>
                 <MainCard sx={{paddingTop:'0px',border:'1px solid #499662'}} border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <>
                    <Box sx={{  backgroundColor: 'transparent'}}>
                      {language == 'en' ? (
                        <List
                          // component="nav"
                          sx={{
                            backgroundColor: 'transparent',
                            borderRadius: '10px',
                            [theme.breakpoints.down('md')]: {
                              minWidth: '100%'
                            },
                            '& .MuiListItemButton-root': {
                              // mt: 0.5
                            }
                          }}
                        >
                          <ListItemButton
                            sx={{padding:'0px 15px 0px 15px'}}
                            selected={selectedIndex === 2}
                            onClick={handleLogout}
                          >
                            <ListItemIcon sx={{ color: '#ee5b0c'}}>
                              <IconLogout stroke={1.5} size="1.5rem" />
                            </ListItemIcon>

                            <ListItemText
                              primary={
                                <Typography variant="body5" sx={{color:'#499662',fontSize:'18px'}}>
                                  <FormattedMessage id="profileSection.logout" />
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        </List>
                      ) : (
                        <div dir="rtl">
                          <List
                            component="nav"
                            sx={{
                              width: '100%',
                              maxWidth: 450,
                              minWidth: 400,
                              backgroundColor: theme.palette.background.paper,
                              borderRadius: '10px',
                              [theme.breakpoints.down('md')]: {
                                minWidth: '100%'
                              },
                              '& .MuiListItemButton-root': {
                                // mt: 0.5
                              }
                            }}
                          >
                            {/* <ListItemButton
                                                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                            selected={selectedIndex === 0}
                                                            // onClick={(event) => handleListItemClick(event, 0, '/user/account-profile/profile1')}
                                                        >
                                                            <ListItemIcon>
                                                                <IconSettings stroke={1.5} size="1.3rem" />
                                                            </ListItemIcon>

                                                            <ListItemText
                                                                primary={
                                                                    <Typography style={{ textAlign: 'right' }} variant="body2">
                                                                        <FormattedMessage id="profileSection.accountSettings" />
                                                                    </Typography>
                                                                }
                                                            />
                                                        </ListItemButton> */}
                            <ListItemButton
                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                              selected={selectedIndex === 1}
                              onClick={(event) => handleListItemClick(event, 0, '/user')}

                              // onClick={(event) => handleListItemClick(event, 1, '/user/social-profile/posts')}
                            >
                              <ListItemText
                                primary={
                                  <Grid container spacing={1} justifyContent="space-between">
                                    <Grid item>
                                      <Typography variant="body4">
                                        <FormattedMessage id="profileSection.myProfile" />
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                }
                              />
                            </ListItemButton>
                            <ListItemButton
                              sx={{ borderRadius: `${customization.borderRadius}px` }}
                              selected={selectedIndex === 2}
                              onClick={handleLogout}
                            >
                              <ListItemIcon>
                                <IconLogout stroke={3} size="1.5rem" />
                              </ListItemIcon>

                              <ListItemText
                                primary={
                                  <Typography style={{ textAlign: 'right' }} variant="body2">
                                    <FormattedMessage id="profileSection.logout" />
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </List>
                        </div>
                      )}
                    </Box>
                  </>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
