import {
    GET_ALL_INDIVIDUALS,
    GET_ALL_INDIVIDUALS_SUCCESS,
    DELETE_USER1,
    ADD_GUEST_USER,
    ADD_RESTRICTED_USER,
    CHANGE_USER_STATUS
} from './constants';

export const getAllIndividuals = (data) => {
    console.log('getAllIndividuals ACTION');
    return {
        type: GET_ALL_INDIVIDUALS,
        payload: data
    };
};

export const getAllIndividualsSuccess = (data) => {
    return {
        type: GET_ALL_INDIVIDUALS_SUCCESS,
        payload: data
    };
};

export const deleteUser = (data) => {
    return {
        type: 1,
        payload: data
    };
};

export const addGuestUser = (data) => {
    return {
        type: ADD_GUEST_USER,
        payload: data
    };
};

export const addRestrictedUser = (data) => {
    return {
        type: ADD_RESTRICTED_USER,
        payload: data
    };
};

export const changeUserStatus = (data) => {
    return {
        type: CHANGE_USER_STATUS,
        payload: data
    };
};
