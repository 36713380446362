import axios from 'utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { GET_SUPER_ADMIN_DASHBOARD } from './constants';
import { getDashboardDataSuccess } from './actions';
import { getGraphDataSuccess } from './actions';
import { GET_Graph_DASHBOARD } from './constants';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from 'store/Selector';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* getDashboardDataRequest() {
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };

    const response = yield axios.get(`/admin/getAllDashboardData`, headers);
    yield put(getDashboardDataSuccess(response.data.data));
    // yield SetNotification('success', response.data.message);
  } catch (error) {
    console.log(error);
  }
}

export function* WatchDashboardData() {
  yield takeLatest(GET_SUPER_ADMIN_DASHBOARD, getDashboardDataRequest);
}

function* getGraphDataRequest({ payload }) {
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };

    const response = yield axios.get(`admin/transaction-graph?date=${payload.date}`, headers);
    yield put(getGraphDataSuccess(response.data.data));
    // yield SetNotification('success', response.data.message);
  } catch (error) {
    console.log(error);
  }
}

export function* WatchGraphData() {
  yield takeLatest(GET_Graph_DASHBOARD, getGraphDataRequest);
}
export default function* superAdminDashboardSaga() {
  yield all([fork(WatchDashboardData)]);
  yield all([fork(WatchGraphData)]);
}
