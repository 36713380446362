import produce from 'immer';
import { GET_ALL_INDIVIDUALS_SUCCESS } from './constants';

const INITIAL_STATE = {
    usersList: []
};

const usersReducer = produce((draft, action) => {
    switch (action.type) {
        case GET_ALL_INDIVIDUALS_SUCCESS:
            draft.usersList = action.payload;
            console.log(draft.usersList, 'draft.usersList****');
            break;

        default:
    }
}, INITIAL_STATE);

export default usersReducer;
