import axios from 'utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from '../../shared/helperMethods/Selectors';
import {  getPromoCodeMembersSuccess } from './actions';
import {
   
  GET_ALL_PROMO_CODE,
  PROMO_CODE,
  DELETE_PROMO_CODE,
  GET_PROMO_CODE_MEMBER
} from './constants';
import { SetNotification } from 'shared/helperMethods/setNotification';
import { getAllPromosSuccess } from './actions';
function* getAllPromoCodeRequest({ payload }) {
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };

    const response = yield axios.get(`/admin/getAllPromoCode?search=${payload.search}&page=${payload.page}&size=${payload.size}`, headers);
    yield put(getAllPromosSuccess(response.data.data));
    console.log('promo response--->', response.data);
  } catch (error) {
    console.log(error, 'error************');
  }
}

export function* watchGetAllPromoCodeRequest() {
  yield takeLatest(GET_ALL_PROMO_CODE, getAllPromoCodeRequest);
}
function* promoCodeRequest({ payload }) {
  try {
    let data = {
      code: payload.code,
      channelName: payload.channelName,
      expiryDate: payload.expiryDate
    };
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
    const response = yield axios.post(`/admin/createPromoCode`, data, headers);
    
    yield SetNotification('success', response.data.message);
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
  }
}
export function* watchPromoCode() {
  yield takeLatest(PROMO_CODE, promoCodeRequest);
}
function* deletePromoCodeRequest({ payload }) {
    console.log("del payload--->", payload)
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
    const response = yield axios.delete(`/admin/deletePromoCode/delete/${payload.id}`, headers);
    payload?.handleClose();
    payload?.getAllCodesListing();

    yield SetNotification('success', response.data.message);
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
  }
}

export function* watchPromoCodeMemberEvent() {
  yield takeLatest(GET_PROMO_CODE_MEMBER, promoCodeMemberRequest);
}

function* promoCodeMemberRequest({ payload }) {
  try {
    const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };

    const response = yield axios.get(`/admin/getAllPromoCodeMember/${payload.id}`, headers);
    yield put(getPromoCodeMembersSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response.data.data);
  }
}

export function* watchDeleteEvent() {
  yield takeLatest(DELETE_PROMO_CODE, deletePromoCodeRequest);
}

export default function* promoCodeSaga() {
  yield all([fork(watchPromoCode), fork(watchGetAllPromoCodeRequest), fork(watchDeleteEvent), fork(watchPromoCodeMemberEvent)]);
}
