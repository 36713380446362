import { GET_ALL_Fee, GET_ALL_FEE_SUCCESS,GET_ALL_Enable,GET_ALL_Enable_SUCCESS,SET_LOADER } from './constants';

export const getAllFee = (data) => {
    return {
        type: GET_ALL_Fee,
        payload: data
    };
};

export const getAllfeeSuccess = (data) => {
    return {
        type: GET_ALL_FEE_SUCCESS,
        payload: data
    };
};
export const getAllenable = (data) => {
    return {
        type: GET_ALL_Enable,
        payload: data
    };
};

export const getAllEnableSuccess = (data) => {
    return {
        type: GET_ALL_Enable_SUCCESS,
        payload: data
    };
};
export const setLoader = (data) => {
    return {
        type: SET_LOADER,
        payload: data
    };
};