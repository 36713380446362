import produce from 'immer';
import { GET_ALL_FEE_SUCCESS,SET_LOADER, } from './constants';

const INITIAL_STATE = {
    fee: []
};

const FeeReducer = produce((draft, action) => {
    switch (action.type) {
        case GET_ALL_FEE_SUCCESS:
            draft.fee = action.payload;
            console.log(draft.fee, 'draft****');
            break;
            case SET_LOADER:
            draft.loader = action.payload;
            break;
        default:
            break;
    }
}, INITIAL_STATE);

export default FeeReducer;
